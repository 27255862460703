(function(window, document, $, undefined) {

    $(function() {
        $('.user-multimenu').on('click', function(e) {
            e.preventDefault();
        });
    });

})(window, document, jQuery);


(function(window, document, $, undefined) {
    $(function() {
        $('.tn-login-form-container form').on('submit', function() {
            $('.tn-login-form-container').css({ '-webkit-filter': 'blur(5px)'})
            //$('.tn-login-submit').show();
        });
                
        $('.user-help-popover').popover({
        	sanitize: false,
            trigger: 'hover'
        });
    });
})(window, document, jQuery);